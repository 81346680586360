import React, { useState } from 'react';
import clsx from 'clsx';

import { Card, Container, List, ListItem } from '@material-ui/core';

import ChangePassword from '../ChangePassword';


const Settings1 = () => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>
      <Card>
        <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-0">
          <List
            component="div"
            className="w-100 nav-line justify-content-center d-flex nav-line-alt nav-tabs-primary">
            <ListItem
              button
              className="p-4 font-size-md rounded-0"
              selected={activeTab === '1'}
              onClick={() => toggle('1')}>
              <span className="font-weight-bold font-size-sm text-uppercase">
                Account
              </span>
              <div className="divider" />
            </ListItem>
            <ListItem
              button
              className="p-4 font-size-md rounded-0"
              selected={activeTab === '2'}
              onClick={() => toggle('2')}>
              <span className="font-weight-bold font-size-sm text-uppercase">
                Notifications
              </span>
              <div className="divider" />
            </ListItem>
            <ListItem
              button
              className="p-4 font-size-md rounded-0"
              selected={activeTab === '3'}
              onClick={() => toggle('3')}>
              <span className="font-weight-bold font-size-sm text-uppercase">
                eCommerce Platforms
              </span>
              <div className="divider" />
            </ListItem>
          </List>
        </div>
        <div className="px-0 py-0 py-lg-4">
          <div
            className={clsx('tab-item-wrapper', { active: activeTab === '1' })}
            index={1}>
            <Container className="py-3">
              <div>
                <div className="card-header d-flex align-items-center bg-transparent card-header-alt px-0 pb-4">
                  <div>
                    <h6 className="font-weight-bold font-size-xl mb-1 text-black">
                      Account
                    </h6>
                    <p className="text-black-50 mb-0">
                      Manage your account settings from the section below.
                    </p>
                  </div>
                </div>
              </div>
              <List className="list-group-bordered">
                <ListItem className="d-block d-lg-flex justify-content-between align-items-center py-3">
                  <div className="d-flex align-items-center mr-0 mr-md-4">
                    <div>
                      <div className="font-weight-bold">Change Password</div>
                      <span className="opacity-6 d-block">
                        You can change the password for your account using this
                        dialog window.
                      </span>
                    </div>
                  </div>

                  <ChangePassword />
                </ListItem>
              </List>
            </Container>
          </div>
          {/* <div
            className={clsx('tab-item-wrapper', { active: activeTab === '2' })}
            index={2}>
            <Notifications />
          </div> */}
          {/* <div
            className={clsx('tab-item-wrapper', { active: activeTab === '3' })}
            index={3}>
            <Container className="py-3">
              <div>
                <div className="card-header d-flex align-items-center bg-transparent card-header-alt px-0 pb-4">
                  <div>
                    <h6 className="font-weight-bold font-size-xl mb-1 text-black">
                      Connect to other Platforms
                    </h6>
                    <p className="text-black-50 mb-0">
                      You can connect your accounts on ther popular ecommerce
                      platform to sync inventory items listing.
                    </p>
                  </div>
                </div>
              </div>
              <Platforms />
            </Container>
          </div> */}
        </div>
      </Card>
    </>
  );
};

export default Settings1;
