import React, { useState } from 'react';
import { connect } from 'react-redux';

import {
  Tooltip,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import 'react-csv-importer/dist/index.css';
import InfoIcon from '@material-ui/icons/Info';

import { addBarcode } from '../../../reducers/Barcodes';

import TabItemWrapper from '../TabItemWrapper';
import { Importer, ImporterField } from 'react-csv-importer';

const FileUpload = ({ addBarcode }) => {
  const sources = [
    { label: 'Amazon', value: 'amazon' },
    { label: 'Home Depot', value: 'homedepot' }
  ];
  const [source, setSource] = useState('');
  const handleSource = (e) => setSource(e.target.value);
  const guessFormat = (format) => {
    const value = format;
    const asinRegex = /^(B0|BT)[0-9A-Z]{8}$/;
    const upcRegex = /^\d{12}|\d{13}$/;

    if (asinRegex.test(value)) return 'asin';
    else if (upcRegex.test(value)) return 'upc';
    else return 'sku';
  };
  // const onDrop = useCallback(
  //   (acceptedFiles) => {
  //     console.log(`Accepted files: ${acceptedFiles.length}`);
  //     acceptedFiles.forEach((file) => {
  //       const reader = new FileReader();
  //       reader.readAsBinaryString(file);

  //       reader.onabort = () => console.log('file reading was aborted');
  //       reader.onerror = () => console.log('file reading has failed');
  //       reader.onload = () => {
  //         const data = reader.result;
  //         const rows = data.split('\n');
  //         const barcodesList = rows.map((row) => ({
  //           value: row.split(',')[0]?.trim(),
  //           format: row.split(',')[1]?.trim() || 'upc',
  //           quantity: row.split(',')[2]?.trim() || 1,
  //           price: row.split(',')[3]?.trim() || null,
  //           condition: row.split(',')[4]?.trim() || 'New',
  //           source
  //         }));
  //         barcodesList.forEach((barcode, index) => {
  //           console.log(index);
  //           if (barcode.value && index > 2) addBarcode(barcode);
  //         });

  //         // for (const barcode of barcodesList) {
  //         //   if (barcode.value) addBarcode(barcode);
  //         // }
  //       };
  //     });
  //   },
  //   [source, addBarcode]
  // );

  // const {
  //   acceptedFiles,
  //   isDragActive,
  //   isDragAccept,
  //   isDragReject,
  //   getRootProps,
  //   getInputProps
  // } = useDropzone({ onDrop, accept: '.csv' });

  // const files = acceptedFiles.map((file) => (
  //   <ListItem
  //     className="font-size-sm px-3 py-2 text-primary d-flex justify-content-between align-items-center"
  //     key={file.path}>
  //     <span>{file.path}</span>{' '}
  //     <span className="badge badge-pill bg-neutral-warning text-warning">
  //       {file.size} bytes
  //     </span>
  //   </ListItem>
  // ));

  return (
    <TabItemWrapper
      title="Batch Upload"
      subtitle={
        <>
          Upload CSV file with a list of barcodes.{' '}
          <Tooltip
            title={
              <>
                {'Only csv file format are supported yet. Use attached demo. '}
                <br />
                <table>
                  <thead>
                    <th colSpan={2}>Identifier</th>
                    <th colSpan={2}>Format</th>
                    <th colSpan={2}>Quantity</th>
                    <th colSpan={2}>Price</th>
                    <th colSpan={2}>Condition</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={2}>123456789012</td>
                      <td colSpan={2}>upc</td>
                      <td colSpan={2}>1</td>
                      <td colSpan={2}>10.00</td>
                      <td colSpan={2}>New</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  {' '}
                  Condition Supported values: "new"; "used_like_new";
                  "used_good"; "used_fair" :{' '}
                </p>
                <p>
                  Format Supported values: "upc" for 12 digits barcode; "sku"
                  for HD internet number or model number; "asin" for Amz
                  products
                </p>
                <br />
              </>
            }>
            <InfoIcon fontSize="large" />
          </Tooltip>
          <p>
            A demo file can be downloaded from{' '}
            <a
              href={
                'https://drive.google.com/file/d/1mzLxCIfYpffgJ7sMOUsqE3c4tBYb2-pd/view'
              }>
              here
            </a>
          </p>
        </>
      }>
      <Grid container spacing={1} className="mb-3">
        <Grid item md={1}>
          <label className="font-weight-bold py-2">
            Source <span className="text-danger">*</span>
          </label>
        </Grid>
        <Grid item md={11}>
          <RadioGroup
            aria-label="source"
            name="source"
            value={source}
            onChange={handleSource}
            row={true}>
            {sources.map(({ label, value }, i) => (
              <FormControlLabel
                key={i}
                value={value}
                control={<Radio color="primary" />}
                label={label}
              />
            ))}
          </RadioGroup>
        </Grid>
      </Grid>

      <Importer
        chunkSize={10000} // optional, internal parsing chunk size in bytes
        skipEmptyLines={true}
        assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
        restartable={true} // optional, lets user choose to upload another file when import is complete
        onStart={({ file, fields }) => {
          // optional, invoked when user has mapped columns and started import
          console.log('starting import of file', file, 'with fields', fields);
        }}
        processChunk={async (rows) => {
          console.log(rows, rows.length <= 1);
          if (rows.length <= 1) return;
          // rows = rows.filter(
          //   (row) =>
          //     row.upc != '' && row.model_number != '' && row.price != ''
          // );
          // if (rows.length >= 1){
          //   rows = rows.map((row) => ({ ...row, source: sources }));
          // return;
          // }

          // value: row.split(',')[0]?.trim(),
          // format: row.split(',')[1]?.trim() || 'upc',
          // quantity: row.split(',')[2]?.trim() || 1,
          // price: row.split(',')[3]?.trim() || null,
          // condition: row.split(',')[4]?.trim() || 'New',
          // source

          //   identifier: value,
          // type: format,
          // source,
          // quantity,
          // condition,
          // price
          rows = rows.map((row) => {
            if (row.value)
              addBarcode({
                value: row?.value?.trim(),
                format:
                  guessFormat(row?.value?.trim()) ||
                  row?.format?.trim() ||
                  'upc',
                quantity: row?.quantity?.trim() || 1,
                price: row?.price?.trim() || 0,
                condition: row?.condition?.trim() || 'New',
                source
              });
            return row;
          });
          console.log(rows[0]);
          // required, receives a list of parsed objects based on defined fields and user column mapping;
          // may be called several times if file is large
          // (if this callback returns a promise, the widget will wait for it before parsing more data)
          // console.log('received batch of rows', rows);
          // const barcodesList = rows.map((row) => ({
          //   value: row?.value?.trim(),
          //   format: row?.format?.trim() || 'upc',
          //   quantity: row?.quantity?.trim() || 1,
          //   price: row?.wholesale?.trim() || null,
          //   condition: row?.condition?.trim() || 'New',
          //   source: row?.source?.trim() || source
          // }));
          // console.log('barcodelist', barcodesList);
          // barcodesList.forEach((barcode, index) => {
          //   console.log(index, barcode);
          //   if (barcode.value) addBarcode(barcode);
          // });
          // setData([...data, ...rows]);
        }}
        onComplete={({ file, fields }) => {
          // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
          console.log('finished import of file', file, 'with fields', fields);
          console.log(file, fields);
          // setFieldValue('csv', file);
          // setFieldValue('csv_data', data);
        }}
        // onClose={() => {
        //   // optional, invoked when import is done and user clicked "Finish"
        //   // (if this is not specified, the widget lets the user upload another file)
        //   console.log('importer dismissed');
        // }}
      >
        <ImporterField name="value" label="Scan Code" />

        {/* <ImporterField name="description" label="description" /> */}
        <ImporterField name="condition" label="condition" optional />
        {/* <ImporterField name="model_number" label="Model Number" /> */}
        <ImporterField name="quantity" label="quantity" optional />
        <ImporterField name="price" label="price" optional />
        <ImporterField name="format" label="format" optional />

        {/* <ImporterField name="type" label="format (sku, upc or asin)" optional /> */}
      </Importer>
      {/* <div className="dropzone">
        <div {...getRootProps({ className: 'dropzone-upload-wrapper' })}>
          <input disabled={!source} {...getInputProps()} />
          <div className="dropzone-inner-wrapper">
            {isDragAccept && (
              <div>
                <div className="d-100 btn-icon mb-3 hover-scale-lg bg-success shadow-success-sm rounded-circle text-white">
                  <CheckIcon className="d-50" />
                </div>
                <div className="font-size-sm text-success">
                  All files will be uploaded!
                </div>
              </div>
            )}
            {isDragReject && (
              <div>
                <div className="d-100 btn-icon mb-3 hover-scale-lg bg-danger shadow-danger-sm rounded-circle text-white">
                  <CloseTwoToneIcon className="d-50" />
                </div>
                <div className="font-size-sm text-danger">
                  Some files will be rejected!
                </div>
              </div>
            )}
            {!isDragActive && (
              <div>
                <div className="d-100 btn-icon mb-3 hover-scale-lg bg-white shadow-light-sm rounded-circle text-primary">
                  <CloudUploadTwoToneIcon className="d-50" />
                </div>
                <div className="font-size-sm">
                  Drag and drop files here{' '}
                  <span className="font-size-xs text-dark">(csv)</span>
                </div>
              </div>
            )}

            <small className="py-2 text-black-50">or</small>

            <div>
              <Button
                disabled={!source}
                className="btn-primary hover-scale-sm font-weight-bold btn-pill px-4">
                <span className="px-2">Browse Files</span>
              </Button>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div>
        <div className="font-weight-bold my-4 text-uppercase text-dark font-size-sm text-center">
          Uploaded Files
        </div>
        {files.length <= 0 && (
          <div className="text-info text-center font-size-sm">
            Uploaded demo files will appear here!
          </div>
        )}
        {!source && (
          <div className="text-info text-center font-size-sm text-danger">
            Please select a source
          </div>
        )}
        {files.length > 0 && (
          <div>
            <Alert className="btn-success text-center mb-3">
              You have uploaded <b>{files.length}</b> files!
            </Alert>
            <List className="font-size-sm">{files}</List>
          </div>
        )}
      </div> */}
    </TabItemWrapper>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addBarcode: (barcode) => dispatch(addBarcode(barcode))
});

export default connect(null, mapDispatchToProps)(FileUpload);
